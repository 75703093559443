exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-radiesse-index-tsx": () => import("./../../../src/pages/about-radiesse/index.tsx" /* webpackChunkName: "component---src-pages-about-radiesse-index-tsx" */),
  "component---src-pages-about-radiesse-successful-case-tsx": () => import("./../../../src/pages/about-radiesse/successful-case.tsx" /* webpackChunkName: "component---src-pages-about-radiesse-successful-case-tsx" */),
  "component---src-pages-blog-collagen-hyaluronan-tsx": () => import("./../../../src/pages/blog/collagen-hyaluronan.tsx" /* webpackChunkName: "component---src-pages-blog-collagen-hyaluronan-tsx" */),
  "component---src-pages-blog-how-to-boost-collagen-tsx": () => import("./../../../src/pages/blog/how-to-boost-collagen.tsx" /* webpackChunkName: "component---src-pages-blog-how-to-boost-collagen-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-nasolabial-folds-tsx": () => import("./../../../src/pages/blog/nasolabial-folds.tsx" /* webpackChunkName: "component---src-pages-blog-nasolabial-folds-tsx" */),
  "component---src-pages-certified-medical-center-tsx": () => import("./../../../src/pages/certified-medical-center.tsx" /* webpackChunkName: "component---src-pages-certified-medical-center-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-radiesse-plus-tsx": () => import("./../../../src/pages/radiesse-plus.tsx" /* webpackChunkName: "component---src-pages-radiesse-plus-tsx" */),
  "component---src-pages-radiesse-treatment-tsx": () => import("./../../../src/pages/radiesse-treatment.tsx" /* webpackChunkName: "component---src-pages-radiesse-treatment-tsx" */)
}

